var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"tile":"","offset-y":"","min-width":_vm.width > 550 ? '500' : '100%',"max-width":_vm.width > 550 ? '500' : '100%',"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",null,{"on":on,"attrs":attrs,"items":_vm.notifications,"isActive":_vm.state})]}}],null,true),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('div',{staticClass:"notification-list"},[_vm._l((_vm.notifications),function(item,index){return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,staticClass:"notification-item",on:{"click":function($event){return _vm.handle(item)}}},[_c('div',{staticClass:"notification-icon"},[_c('v-icon',[_vm._v(_vm._s(_vm.getIcon(item)))])],1),_c('div',{staticClass:"notification-action",on:{"click":function($event){$event.stopPropagation();return _vm.closeNotification(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('div',{staticClass:"notification-info"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"info-text"},[(_vm.getUser(item))?_c('span',[_vm._v(_vm._s(_vm.getUser(item))+" | ")]):_vm._e(),_vm._v(" "+_vm._s(item.message)+" "),(item.duedate)?_c('div',{staticClass:"info-date",class:{
              urgent: _vm.getStatus(item) == 1,
              late: _vm.getStatus(item) == 2,
            }},[_vm._v(" bis "+_vm._s(_vm._f("moment")(item.duedate,"calendar", null, { lastDay: "[Gestern]", sameDay: "[Heute]", nextDay: "[Morgen]", lastWeek: "[letzten] dddd", nextWeek: "dddd", sameElse: "L", }))+" ")]):_vm._e()])])])}),(!_vm.notifications)?_c('div',{staticClass:"notification-empty"},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Du hast keine neuen Benachrichtigungen ")],1):_c('a',{staticStyle:{"text-align":"end","padding":"0.5rem 1rem"},on:{"click":_vm.fetchReadNotifications}},[_vm._v(" ältere Nachrichten anzeigen ")]),_vm._l((_vm.readNotifications),function(notification){return _c('div',{key:notification.id,staticClass:"notification-item alt",on:{"click":function($event){return _vm.handle(notification)}}},[_c('div',{staticClass:"notification-icon"},[_c('v-icon',[_vm._v("mdi-clock-time-four-outline")])],1),_c('div',{staticClass:"notification-action",on:{"click":function($event){$event.stopPropagation();return _vm.reopenNotification(notification)}}},[_c('v-icon',[_vm._v("mdi-lock-reset")])],1),_c('div',{staticClass:"notification-info"},[_c('div',{staticClass:"info-title"},[_vm._v(_vm._s(notification.title))]),_c('div',{staticClass:"info-text"},[(_vm.getUser(notification))?_c('span',[_vm._v(_vm._s(_vm.getUser(notification))+" | ")]):_vm._e(),_vm._v(" "+_vm._s(notification.message)+" "),(notification.duedate)?_c('div',{staticClass:"info-date",class:{
              urgent: _vm.getStatus(notification) == 1,
              late: _vm.getStatus(notification) == 2,
            }},[_vm._v(" bis "+_vm._s(_vm._f("moment")(notification.duedate,"calendar", null, { lastDay: "[Gestern]", sameDay: "[Heute]", nextDay: "[Morgen]", lastWeek: "[letzten] dddd", nextWeek: "dddd", sameElse: "L", }))+" ")]):_vm._e()])])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }