export interface NotificationState {
  notifications: Array<Notification>;
  readNotifications: Array<Notification>;
}

export interface Notification {
  sender: string;
  receiver: string;
  read: boolean;
  timestamp: number;
  duedate: number;
  title: string;
  message: string;
  contract: string;
}

export default {
  namespaced: true,
  state: (): NotificationState => ({
    notifications: [],
    readNotifications: [],
  }),
  mutations: {
    setNotifications(state, value) {
      state.notifications = value;
    },
    setReadNotifications(state, value) {
      state.readNotifications = value;
    },
  },
  actions: {
    fetchNotifications({ commit }) {
      this._vm.axios
        .get("/notification?read=false")
        .then((res) => commit("setNotifications", res.data))
        .catch((err) => {
          if (!err?.response || !err.response?.status)
            return commit("ui/error", "Unbekannter Fehler", { root: true });
          if (err.response.status == 400)
            return commit(
              "ui/error",
              "Benachrichtigungen konnten nicht geladen werden",
              { root: true }
            );
          if (err.response.status == 500)
            return commit("ui/error", "Server Fehler", { root: true });
        });
    },
    fetchReadNotifications({ commit }) {
      this._vm.axios
        .get("/notification?read=true&top=99")
        .then((res) => commit("setReadNotifications", res.data))
        .catch((err) => {
          if (!err.response || !err.response.status)
            return commit("ui/error", "Unbekannter Fehler", { root: true });
          if (err.response.status == 400)
            return commit(
              "ui/error",
              "Gelesene Benachrichtigungen konnten nicht geladen werden",
              { root: true }
            );
          if (err.response.status == 500)
            return commit("ui/error", "Server Fehler", { root: true });
        });
    },
  },
};
