<script>
import { mapGetters } from "vuex";

import ContractResponsibleDialog from "../dialogs/ContractResponsibleDialog.vue";

import permissionMixin from "../../mixin/permission.mixin";

export default {
  name: "ContractInfoComponent",
  components: {
    ContractResponsibleDialog,
  },
  mixins: [permissionMixin],
  props: {
    contract: { type: Object, required: true },
    formular: { type: Object, default: undefined },
  },
  computed: {
    ...mapGetters({
      inspectors: "user/inspectors",
    }),
    phone() {
      if (this.contract.phone_direct) return this.contract.phone_direct;
      if (this.contract.phone_mobile) return this.contract.phone_mobile;
      return this.contract.phone_main;
    },
    evu() {
      return this.formular?.data?.power_station || this.contract.evu;
    },
    inspector() {
      return this.inspectors?.find(
        (user) => user.id == this.contract.data?.inspector
      )?.data?.long;
    },
  },
};
</script>

<template>
  <div class="contract-info">
    <div class="item">
      <div class="prefix">Auftrags Nr.</div>
      <div class="data">{{ contract.contract_id }}</div>
    </div>
    <div class="item">
      <div class="prefix">Kommission</div>
      <div class="data">{{ contract.contract_name }}</div>
    </div>
    <div class="item">
      <div class="prefix">EVU</div>
      <div class="data">
        <template v-if="evu">{{ evu }}</template>
        <v-chip v-else small color="error">Nicht definiert</v-chip>
      </div>
    </div>
    <div class="item">
      <div class="prefix">Liefertermin</div>
      <div v-if="contract.data.transport_date" class="data success--text">
        <b>
          {{ new Date(contract.data.transport_date) | moment("DD.MM.YYYY") }}
          {{ contract.data.transport_time }}
        </b>
      </div>
      <div
        v-else-if="contract.delivery_date_mandatory"
        class="data error--text"
      >
        <b
          >{{
            Date.parse(contract.delivery_date_mandatory) | moment("DD.MM.YYYY")
          }}
          {{ contract.delivery_info }}</b
        >
      </div>
      <div v-else class="data">
        {{ Date.parse(contract.delivery_date) | moment("DD.MM.YYYY") }}
        {{ contract.delivery_info }}
      </div>
    </div>
    <div class="item">
      <div class="prefix">Kontakt</div>
      <div class="data">
        <template v-if="contract.company">
          <div>{{ contract.company }}</div>
          <div>{{ contract.display_name || contract.customer_sign }}</div>
          <a
            v-if="contract.email"
            :href="`mailto:${contract.email}?subject=${encodeURI(
              contract.contract_name
            )}`"
          >
            {{ contract.email }}
          </a>
          <br />
          <a v-if="phone" :href="`tel:${phone.replaceAll(' ', '')}`">{{
            phone
          }}</a>
        </template>
        <v-chip v-else small color="error">Nicht definiert</v-chip>
      </div>
    </div>
    <div class="item">
      <div class="prefix">Verantwortlich</div>
      <ContractResponsibleDialog
        :contract="contract"
        :disabled="!hasPermission('contract.responsible.edit')"
        @update:responsible="$emit('update:contract')"
      >
        <template #activator="{ on, attrs }">
          <div
            class="data"
            :class="{ action: hasPermission('contract.responsible.edit') }"
            v-bind="attrs"
            v-on="on"
          >
            {{ contract.data.manufacturer_sign || contract.manufacturer_sign }}
          </div>
        </template>
      </ContractResponsibleDialog>
    </div>
    <div class="item">
      <div class="prefix">Erbauer</div>
      <div v-if="contract.data && contract.data.monteur" class="data">
        {{ contract.data.monteur }}
      </div>
      <div v-else class="data">
        <v-chip small color="error">Nicht definiert</v-chip>
      </div>
    </div>
    <div class="item">
      <div class="prefix">Prüfer</div>
      <div v-if="contract.data && contract.data.inspector" class="data">
        {{ inspector }}
      </div>
      <div v-else class="data">
        <v-chip small color="error">Nicht definiert</v-chip>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contract-info {
  display: grid;
  gap: 1rem;

  .item {
    display: grid;
    grid-template-columns: 130px auto;
    grid-template-rows: auto;
    .prefix {
      font-weight: bold;
    }

    .data {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .data.action {
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }

    .row {
      display: flex;
      gap: 1rem;
    }
  }
}

@media only screen and (max-width: 550px) {
  .contract-info {
    gap: 0.5rem;
    .item {
      display: unset;
    }
  }
}
</style>
