<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "NotificationMenuComponent",
  data() {
    return {
      state: false,
      width: 0,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
      notifications: (state) => state.notification.notifications,
      readNotifications: (state) => state.notification.readNotifications,
    }),
  },
  mounted() {
    this.width = window.innerWidth;
    window.onresize = () => (this.width = window.innerWidth);
  },
  methods: {
    ...mapMutations({
      logWarning: "ui/warning",
    }),
    ...mapActions({
      fetchNotifications: "notification/fetchNotifications",
      fetchReadNotifications: "notification/fetchReadNotifications",
    }),
    getIcon(notification) {
      if (notification.sender && notification.title.startsWith("Erinnerung:"))
        return "mdi-message-reply-text-outline";
      if (
        !notification.sender &&
        notification.title.startsWith("Dateneingang:")
      )
        return "mdi-send";
      return "mdi-bell-outline";
    },
    getUser(notification) {
      const user = this.users.find((item) => item.id == notification.sender);
      if (user) return `${user.username}`;
      else return undefined;
    },
    getPath(notification) {
      if (notification.contract) return "/contract/" + notification.contract;
      return "/";
    },
    getStatus(notification) {
      if (!notification.duedate) return 0;
      const differenc = notification.duedate - Date.now();
      if (differenc > 1000 * 60 * 60 * 24 * 3) return 0;
      if (differenc > 0) return 1;
      else return 2;
    },
    handle(notification) {
      if (notification.contract) {
        const path = "/contract/" + notification.contract;
        if (this.$router.currentRoute.path != path) this.$router.push(path);
      }
    },
    closeNotification(notification) {
      this.axios.patch("/notification/" + notification.id).then(() => {
        this.fetchNotifications();
        this.fetchReadNotifications();
      });
    },
    reopenNotification(notification) {
      console.log("test");
      this.axios.post("/notification/" + notification.id).then(() => {
        this.fetchNotifications();
        this.fetchReadNotifications();
      });
    },
  },
};
</script>

<template>
  <v-menu
    v-model="state"
    tile
    offset-y
    :min-width="width > 550 ? '500' : '100%'"
    :max-width="width > 550 ? '500' : '100%'"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
        :items="notifications"
        :isActive="state"
      />
    </template>
    <div class="notification-list">
      <div
        v-for="(item, index) in notifications"
        :key="index"
        v-ripple
        class="notification-item"
        @click="handle(item)"
      >
        <div class="notification-icon">
          <v-icon>{{ getIcon(item) }}</v-icon>
        </div>
        <div class="notification-action" @click.stop="closeNotification(item)">
          <v-icon>mdi-check</v-icon>
        </div>
        <div class="notification-info">
          <div class="info-title">{{ item.title }}</div>
          <div class="info-text">
            <span v-if="getUser(item)">{{ getUser(item) }} | </span>
            {{ item.message }}
            <div
              v-if="item.duedate"
              class="info-date"
              :class="{
                urgent: getStatus(item) == 1,
                late: getStatus(item) == 2,
              }"
            >
              bis
              {{
                item.duedate
                  | moment("calendar", null, {
                    lastDay: "[Gestern]",
                    sameDay: "[Heute]",
                    nextDay: "[Morgen]",
                    lastWeek: "[letzten] dddd",
                    nextWeek: "dddd",
                    sameElse: "L",
                  })
              }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="!notifications" class="notification-empty">
        <v-icon>mdi-check</v-icon>
        Du hast keine neuen Benachrichtigungen
      </div>

      <a
        v-else
        style="text-align: end; padding: 0.5rem 1rem"
        @click="fetchReadNotifications"
      >
        ältere Nachrichten anzeigen
      </a>

      <div
        v-for="notification of readNotifications"
        :key="notification.id"
        class="notification-item alt"
        @click="handle(notification)"
      >
        <div class="notification-icon">
          <v-icon>mdi-clock-time-four-outline</v-icon>
        </div>
        <div
          class="notification-action"
          @click.stop="reopenNotification(notification)"
        >
          <v-icon>mdi-lock-reset</v-icon>
        </div>
        <div class="notification-info">
          <div class="info-title">{{ notification.title }}</div>
          <div class="info-text">
            <span v-if="getUser(notification)"
              >{{ getUser(notification) }} |
            </span>
            {{ notification.message }}
            <div
              v-if="notification.duedate"
              class="info-date"
              :class="{
                urgent: getStatus(notification) == 1,
                late: getStatus(notification) == 2,
              }"
            >
              bis
              {{
                notification.duedate
                  | moment("calendar", null, {
                    lastDay: "[Gestern]",
                    sameDay: "[Heute]",
                    nextDay: "[Morgen]",
                    lastWeek: "[letzten] dddd",
                    nextWeek: "dddd",
                    sameElse: "L",
                  })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<style lang="scss" scoped>
.notification-list {
  background: white;
  max-height: 50vh;
  overflow-y: auto;
  display: grid;
  grid-template-rows: repeat(6rem);

  .notification-empty {
    width: 100%;
    height: 5rem;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .notification-item {
    overflow: hidden;
    display: grid;
    grid-template-columns: 5rem auto;
    border-bottom: 1px solid $accent;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: $background;

      .notification-icon {
        display: none;
      }

      .notification-action {
        display: flex;
      }
    }

    .notification-icon {
      * {
        height: 5rem;
        width: 5rem;
      }
    }

    .notification-info {
      width: 100%;
      padding: 1rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .info-title {
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 0.2rem;
      }

      .info-text {
        font-weight: 300;
        font-size: 0.9rem;

        span {
          font-weight: 400;
        }

        .info-date {
          margin-top: 1rem;
          height: 100%;
          font-weight: 500;
          font-size: 0.8rem;
          color: $primary;
        }

        .info-date.urgent {
          color: $warning;
        }

        .info-date.late {
          color: $error;
        }
      }
    }

    .notification-action {
      display: none;
      background-color: white;
      transition: background-color 0.2s ease-in-out;
      box-shadow: $box-shadow-1;

      * {
        height: 5rem;
        width: 5rem;
      }

      &:hover {
        background: $success;
      }
    }
  }

  .notification-item.alt {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      background: rgba(0, 0, 0, 0.08);
    }
  }
}
</style>
