var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',{ref:"dropzone",attrs:{"loading":_vm.contract == undefined},scopedSlots:_vm._u([{key:"navbar",fn:function(){return [_c('NavButton',{attrs:{"icon":"mdi-folder","label":"Ablage","active":true}})]},proxy:true},{key:"navbar.append",fn:function(){return [_c('ContractSearchMenu',{scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on, isActive }){return [_c('NavGroup',{attrs:{"active":isActive}},[_c('TextField',_vm._b({attrs:{"icon":"mdi-magnify","width":"min(100%, 300px)","rounded":"","placeholder":"Suche"},on:{"submit":function($event){_vm.queryContracts(_vm.search);
              on();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'TextField',attrs,false))],1)]}}])})]},proxy:true},{key:"default",fn:function(){return [_c('ContractLayout',{scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('FileMenu',{attrs:{"contract":_vm.contract,"rapport":_vm.rapport,"legend":_vm.legend,"formular":_vm.formular,"templates":_vm.templates},on:{"create:rapport":function($event){_vm.fetchRapport();
            _vm.fetchJournal();},"create:legend":function($event){_vm.fetchLegend();
            _vm.fetchJournal();},"create:formular":function($event){_vm.fetchFormular();
            _vm.fetchJournal();},"update:files":function($event){_vm.fetchFiles();
            _vm.fetchJournal();
            _vm.fetchTemplates();}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","disabled":_vm.contract.status == 4}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])}),_c('v-spacer'),(
            _vm.isInGroup('monteur') &&
            _vm.contract.status >= 2 &&
            !_vm.isService &&
            !_vm.isMaterial &&
            !_vm.isEmptySystem
          )?_c('v-btn',{attrs:{"icon":"","outlined":""},on:{"click":function($event){return _vm.printFiles()}}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e(),(
            _vm.isInGroup('inspector') &&
            _vm.hasPermission('contract.status.promote') &&
            _vm.contract.status < 3 &&
            _vm.contract.status > 0 &&
            !_vm.isService &&
            !_vm.isMaterial
          )?_c('InspectionDialog',{attrs:{"contract":_vm.contract,"formular":_vm.formular,"checklist":_vm.checklist},on:{"update:contract":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();},"update:formular":function($event){_vm.fetchFormular();
            _vm.fetchJournal();}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-decagram-outline")])],1)]}}],null,false,3233113611)}):_vm._e(),(
            (_vm.isInGroup('avor') &&
              ((_vm.hasPermission('contract.status.promote') &&
                _vm.contract.status == 3) ||
                (_vm.hasPermission('contract.status.demote') &&
                  _vm.contract.status == 4))) ||
            (_vm.isService &&
              (_vm.isInGroup('avor') || _vm.isInGroup('installer')) &&
              _vm.contract.status == 1 &&
              _vm.hasPermission('contract.status.promote')) ||
            (_vm.isMaterial &&
              _vm.isInGroup('avor') &&
              _vm.contract.status == 1 &&
              _vm.hasPermission('contract.status.promote'))
          )?_c('ArchiveDialog',{attrs:{"contract":_vm.contract},on:{"update:contract":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.contract.status < 4 ? "mdi-archive-outline" : "mdi-archive-sync-outline"))])],1)]}}],null,false,548988248)}):_vm._e(),(
            _vm.isInGroup('avor') &&
            _vm.hasPermission('contract.status.promote') &&
            (_vm.contract.status == 0 || _vm.contract.status == undefined)
          )?_c('ContractReleaseDialog',{attrs:{"contract":_vm.contract,"checklist":_vm.checklist},on:{"update:contract":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-decagram-outline")])],1)]}}],null,false,3233113611)}):_vm._e(),(_vm.hasPermission('email.send'))?_c('EmailMenu',{attrs:{"contract":_vm.contract,"files":_vm.files,"formular":_vm.formular,"rapport":_vm.rapport,"legend":_vm.legend},on:{"update:contract":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-outline")])],1)]}}],null,false,1421557302)}):_vm._e(),(_vm.hasPermission('contract.duedate.set') && _vm.contract.status != 4)?_c('DuedateDialog',{attrs:{"contract":_vm.contract},on:{"update:duedate":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-month-outline")])],1)]}}],null,false,2697080149)}):_vm._e(),(!_vm.isLocked(_vm.contract))?_c('ContractNotesDialog',{attrs:{"contract":_vm.contract},on:{"update:notes":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-long")])],1)]}}],null,false,1116776461)}):_vm._e(),_c('NotificationDialog',{attrs:{"contract":_vm.contract},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-bell-plus-outline")])],1)]}}])}),(_vm.hasSpecificPermission('contract.status.set'))?_c('ContractStatusDialog',{attrs:{"contract":_vm.contract},on:{"update:contract":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-security")])],1)]}}],null,false,796105842)}):_vm._e(),_c('PrintDialog',{ref:"print_dialog",on:{"close":_vm.printNext}})]},proxy:true},{key:"default",fn:function(){return [_c('ContractFileExplorer',{attrs:{"contract":_vm.contract,"rapport":_vm.rapport,"legend":_vm.legend,"formular":_vm.formular,"files":_vm.files},on:{"update:formular":function($event){_vm.fetchFormular();
            _vm.fetchJournal();},"update:rapport":function($event){_vm.fetchRapport();
            _vm.fetchJournal();},"update:legend":function($event){_vm.fetchLegend();
            _vm.fetchJournal();},"update:data":function($event){_vm.fetchFormular();
            _vm.fetchJournal();},"update:files":function($event){_vm.fetchFiles();
            _vm.fetchJournal();
            _vm.fetchTemplates();},"update:contract":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();}}})]},proxy:true},{key:"info",fn:function(){return [_c('ContractInfo',{attrs:{"contract":_vm.contract,"formular":_vm.formular},on:{"update:contract":function($event){return _vm.fetchContract(_vm.contract_id)}}})]},proxy:true},{key:"norms",fn:function(){return [_c('ContractNorm',{attrs:{"contract":_vm.contract,"formular":_vm.formular,"customer_norms":_vm.customer_norms,"evu_norms":_vm.evu_norms},on:{"update:notes":function($event){return _vm.fetchContract(_vm.contract_id)}}})]},proxy:true},{key:"checklist",fn:function(){return [_c('ContractTimeline',{attrs:{"contract":_vm.contract,"journal":_vm.journal,"checklist":_vm.checklist},on:{"update:contract":function($event){_vm.fetchContract(_vm.contract_id);
            _vm.fetchJournal();}}})]},proxy:true}])}),_c('FileTypeDialog',{attrs:{"value":_vm.file_upload,"contract":_vm.contract},on:{"update:files":function($event){_vm.fetchFiles();
        _vm.fetchJournal();}}}),_c('div',{staticClass:"dropzone"},[_vm._v("Datei hochladen")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }