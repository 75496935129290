<script>
import { mapMutations } from "vuex";

import permissionMixin from "@mixin/permission.mixin";

export default {
  name: "ContractNormComponent",
  mixins: [permissionMixin],
  props: {
    contract: { type: Object, required: true },
    formular: { type: Object, default: undefined },
    customer_norms: { type: Array, default: () => [] },
    evu_norms: { type: Array, default: () => [] },
  },
  computed: {
    showNotes() {
      return this.contract.notes && this.contract.notes.length > 0;
    },
    showCustomerRequests() {
      return this.customer_request && this.customer_request.length > 0;
    },
    showCustomerNorms() {
      return this.customer_norms && this.customer_norms.length > 0;
    },
    showEVUNorms() {
      return this.evu_norms && this.evu_norms.length > 0;
    },
    customer_request() {
      return this.formular?.data?.customer_request;
    },
  },
  methods: {
    ...mapMutations({
      logError: "ui/error",
      logMessage: "ui/log",
    }),
    calcIpk(icp) {
      if (icp <= 5) return Math.round(icp * 1.5);
      if (icp > 5 && icp <= 10) return Math.round(icp * 1.7);
      if (icp > 10 && icp <= 20) return Math.round(icp * 2);
      if (icp > 20 && icp <= 50) return Math.round(icp * 2.1);
      return Math.round(icp * 2.2);
    },
    calcIcp(ipk) {
      if (ipk <= 8) return Math.round(ipk / 1.5);
      if (ipk > 8 && ipk <= 17) return Math.round(ipk / 1.7);
      if (ipk > 17 && ipk <= 40) return Math.round(ipk / 2);
      if (ipk > 40 && ipk <= 105) return Math.round(ipk / 2.1);
      return Math.round(ipk * 2.2);
    },
    deleteNote(note_id) {
      this.axios
        .delete(`/contract/${this.contract.contract_id}/note/${note_id}`)
        .then(() => {
          this.logMessage("Notizen wurden gelöscht");
          this.$emit("update:notes");
        })
        .catch(() => {
          this.logError("Es ist ein fehler aufgetreten");
        });
    },
  },
};
</script>

<template>
  <div class="norm-main">
    <div v-if="!showNotes && !showCustomerNorms && !showEVUNorms" class="empty">
      Keine Normen vorhanden
    </div>
    <div v-if="showNotes || (formular && formular.data)">
      <div class="norm-title">Auftragsinformationen:</div>
      <ul style="margin-bottom: 0.5rem">
        <template v-if="formular && formular.data">
          <li v-if="formular.data.halogenfree">halogenfrei</li>
          <li v-if="formular.data.temperature">
            Umgebungstemperatur über 40°c
          </li>
          <li v-if="formular.data.humidity">
            Hohe Luftfeuchtigkeit (mehr als 50%)
          </li>
          <li v-if="formular.data.altitude">Höhenlage höher als 2000 m.ü.M</li>
          <li v-if="formular.data.temp_hum_change">
            Schnelle Temperatur und oder Luftdruckänderungen
          </li>
          <li v-if="formular.data.dust">
            Staubige Umgebung (z.B. Schreinerei)
          </li>
          <li v-if="formular.data.magnetic_field">
            Einwirkung starker elektrischer oder magnetischer Felder
          </li>
          <li v-if="formular.data.explosive">
            Montageort in feuer oder explosionsgefährdeten Bereichen
          </li>
          <li v-if="formular.data.rated_current">
            Bemessungsstrom: {{ formular.data.rated_current.join("A, ") }}A
          </li>
          <li v-if="formular.data.system_design">
            Äussere Bauform: {{ formular.data.system_design }}
            {{
              formular.data.system_design == "Geschlossene Bauform"
                ? "IP2XC"
                : formular.data.ip_protection || "IP40"
            }}
          </li>
          <li v-if="formular.data.ipk">Ipk: &lt;={{ formular.data.ipk }}kA</li>
          <li v-if="formular.data.ipk">
            Icp: &lt;={{ calcIcp(formular.data.ipk) }}kA
          </li>
          <li v-if="formular.data.icp">
            Ipk: &lt;={{ calcIpk(formular.data.icp) }}kA
          </li>
          <li v-if="formular.data.icp">Icp: &lt;={{ formular.data.icp }}kA</li>
          <li v-if="formular.data.icc">Icc: {{ formular.data.icc }}kA</li>
          <li v-if="formular.data.access">
            Verwendung durch: {{ formular.data.access }}
          </li>
          <li
            v-if="
              formular.data.protection ||
              (formular.data.access && formular.data.access == 'Laien')
            "
          >
            Schutzart: {{ formular.data.protection || "IP2XC" }}
          </li>
        </template>
      </ul>
      <div v-if="contract.notes && contract.notes.length > 0">
        <div class="norm-title">Notizen:</div>
        <table style="width: 100%">
          <tr
            v-for="note of contract.notes"
            :key="note._id"
            style="vertical-align: top"
          >
            <td>
              <b style="text-transform: uppercase">{{ note.short }}: </b>
            </td>
            <td style="padding: 0 0.5rem" class="norm-text">{{ note.text }}</td>
            <td
              v-if="hasPermission('contract.note.delete')"
              style="font-size: 0.8rem; text-align: end"
              class="note-date"
            >
              <div class="note-date-value">
                {{ note.timestamp | moment("DD.MM.YYYY") }}
              </div>
              <div class="note-date-action">
                <v-btn
                  density="compact"
                  icon
                  color="error"
                  :disabled="!note._id"
                  @click="deleteNote(note._id)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </td>
            <td v-else style="font-size: 0.8rem; text-align: end">
              {{ note.timestamp | moment("DD.MM.YYYY") }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="showCustomerRequests">
      <div class="norm-title">Kundenwünsche:</div>
      <div class="norm-text">{{ customer_request }}</div>
    </div>
    <div v-if="showCustomerNorms">
      <div class="norm-title">Kundennormen:</div>
      <ul>
        <li v-for="(norm, index) in customer_norms" :key="index">
          {{ norm.text }}
        </li>
      </ul>
    </div>
    <div v-if="showEVUNorms">
      <div class="norm-title">EW-Normen:</div>
      <ul>
        <li v-for="(norm, index) in evu_norms" :key="index">{{ norm.text }}</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.norm-main {
  display: grid;
  gap: 1rem;

  .norm-title {
    font-weight: 500;
    margin-bottom: 5px;
    border-bottom: 1px solid $accent;
  }

  .norm-text {
    white-space: pre-wrap;
  }
}

.empty {
  width: 100%;
  display: flex;
  justify-content: center;
}

.note-date {
  .note-date-value {
    display: block;
  }

  .note-date-action {
    display: none;
  }

  &:hover {
    .note-date-value {
      display: none;
    }

    .note-date-action {
      display: block;
    }
  }
}
</style>
