<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
  name: "ContractNotesDialogComponent",
  components: {
    DialogLayout,
  },
  props: {
    contract: { type: Object, required: true },
  },
  data() {
    return {
      form: false,
      short: null,
      text: "",
      requiredRule: [(v) => !!v || "Eingabe erforderlich"],
    };
  },
  watch: {
    contract: {
      immediate: true,
      handler(contract) {
        if (contract) this.notes = contract.notes || "";
      },
    },
  },
  methods: {
    ...mapMutations({
      logError: "ui/error",
      logMessage: "ui/log",
    }),
    close(action) {
      action();
      this.$emit("close");
    },
    save(action) {
      this.axios
        .post("/contract/" + this.contract.contract_id + "/note", {
          short: this.short,
          text: this.text,
        })
        .then(() => {
          this.logMessage("Notizen wurden gespeichert");
          this.$emit("update:notes");
          this.close(action);
        })
        .catch(() => this.logError("Es ist ein fehler aufgetreten"));
    },
  },
};
</script>

<template>
  <DialogLayout title="Notizen bearbeiten" width="600">
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <template #default>
      <v-form v-model="form">
        <v-text-field
          v-model="short"
          label="Kürzel"
          placeholder="xy"
          :rules="requiredRule"
          autofocus
        />
        <v-textarea
          v-model="text"
          :rows="4"
          label="Notizen"
          placeholder="Transparente Platten..."
          :rules="requiredRule"
        />
      </v-form>
    </template>
    <template #action="{ action }">
      <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="!form" color="primary" text @click="save(action)">
        Speichern
      </v-btn>
    </template>
  </DialogLayout>
</template>
